<template>
  <div style="min-height:100vh;padding:.2rem">
    <div class="header" style="padding-bottom:30px">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">报告详情</span>
        </van-col>
      </van-row>
    </div>
    <div class="body">
      <card>
        <template #content>
          <van-cell :title="report.report_type_text" :value="report.submit_time"/>
          <van-cell title="带团数" >
            <a @click="$router.push(`/xiaoxi/reportLists?lists_ids=${report.lists_ids}`)"
              class="link">{{report.lists_count}}</a>
          </van-cell>
          <van-cell title="游客人次统计">
            <a @click="$router.push(`/xiaoxi/reportLists?tourists_ids=${report.tourists_ids}&list_ids=${report.lists_ids}`)"
              class="link">{{report.tourists_count}}</a>
          </van-cell>
          <van-cell title="突发事件次数">
            <a @click="$router.push(`/xiaoxi/reportLists?reports_ids=${report.emergency_reports_ids}`)"
              class="link">{{report.emergency_reports_count}}</a>
          </van-cell>
          <van-cell title="报告内容" :value="report.report_content"/>
          <van-cell title="附件" >
            <template #label>
              <span v-if="report.attachment_url == null">无</span>
              <van-image :src="report.attachment_url" fit="contain" width="200" height="200" @click="showImage"
                v-if="report.attachment_url != null"/>
            </template>
          </van-cell>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import card from "@/views/components/Card"
import {ImagePreview} from "vant"
export default {
  components: {
    card
  },
  data: () => ({
    tabIndex: 0
  }),
  computed: {
    ...mapGetters('report', {
      report: 'getCurrentReport'}
    )
  },
  created() {
    this.tabIndex = this.$route.query.tabIndex
    this.$store.dispatch('report/getReportById', this.$route.params.id)
  },
  methods: {
    onClickBack() {
      this.$router.push({path: '/xiaoxi/main', query: {tabIndex: this.tabIndex}})
    },
    showImage() {
      ImagePreview({images: [this.report.attachment_url], closeable: true})
    }
  }
}
</script>

<style scoped>
.link {
  color: #1989fa;
}
.body {
  overflow: hidden;
  overflow-y: scroll;
  height: 90vh;
  padding-bottom: 20vh;
}
</style>